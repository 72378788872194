import React from "react";
import { Privacy } from "../components";
import Layout from "../components/layout";

const Privacypage = () => {
	return <Layout>
		<Privacy />
	</Layout>
};

export default Privacypage;
